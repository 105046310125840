@import "./variables";
@import "./fonts";

html {
  font-size: 1px;
}

body {
  margin: 0;
  font-family: "Blender Pro";
  min-height: 100vh;
  background-color: black;
  color: $colorWhite;
  font-size: 24rem;
  line-height: 25px;
  @media (max-width: 1024px) {
    font-weight: 500;
    font-size: 20rem;
    line-height: 20px;
  }
  @media (max-width: 768px) {
    font-weight: 500;
    font-size: 12rem;
    line-height: 10px;
  }
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  height: 100%;
}

.inner {
  max-width: 768px;
  margin: 0 auto;
  width: 100%;
  @media (max-width: 1024px) {
    padding: 0 84px;
    margin: 0 auto;
    width: 100%;
  }

  @media (max-width: 768px) {
    padding: 0 80px;
    margin: 0 auto;
    width: 100%;
  }
  @media (max-width: 480px) {
    padding: 0 54px;
    margin: 0 auto;
    width: 100%;
  }
}

a {
  color: inherit !important;
  text-decoration: none;
}
