@import "../../styles/variables";

.main {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  height: 100%;
  position: relative;
  margin-bottom: 15vh;

  @media (max-width: 1500px) {
    display: flex;
    flex-direction: column;
  }
}

.side {
  display: flex;
  justify-content: center;
}

.logoWrapper {
  position: fixed;
  top: 130px;
  z-index: -1;
  @media (max-width: 1500px) {
    position: initial;
    margin-top: 30px;
    margin-left: 0;
    display: flex;
    justify-content: center;
  }
  @media (max-width: 768px) {
    margin-top: 0;
  }
}

.logo {
  display: block;
  width: 380px;
  height: 380px;
  @media (max-width: 1500px) {
    width: 260px;
    height: 260px;
  }
  @media (max-width: 768px) {
    width: 122px;
    height: 122px;
  }
}

.logoOutline {
  position: absolute;
  width: 100%;
  height: 1430px;
  top: 0;
  left: 0;
  top: 0;
  right: 0;
  z-index: -1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    height: 50vh;
  }
}

.background {
  position: fixed;
  inset: 0;
  height: 100%;
  background-image: url("../../../public/background.png");
  opacity: 0.25;
  z-index: -1;
  background-size: 4vh;
  @media (max-width: 768px) {
  }
}

.inner {
  padding-top: 130px;
  @media (max-width: 1500px) {
    padding-top: 0;
  }
}

.welcome {
  margin-bottom: 44px;
  @media (max-width: 768px) {
  }
}

.question {
  &:not(:last-child) {
    margin-bottom: 60px;
  }
}

.language {
  position: fixed;
  top: 10px;
  right: 20px;

  @media (max-width: 1500px) {
    position: absolute;
  }
  @media (max-width: 768px) {
    top: 0;
    right: 10px;
  }
}

.info {
  margin-top: 72px;
}

.infoText {
  margin-bottom: 25px;
  & > span {
    color: $colorOrange;
  }
}

.toast {
  font-family: inherit !important;
  margin-bottom: 15px !important;
  font-size: 15px;
}
