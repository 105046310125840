@import "../../styles/variables";

.welcome {
  margin-bottom: 40px;
  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
  @media (max-width: 480px) {
    margin-bottom: 15px;
  }


  & > p {
    &:not(:last-child) {
      margin-bottom: 20px;
      @media (max-width: 768px) {
        margin-bottom: 20px;
      }
      @media (max-width: 480px) {
        margin-bottom: 10px;
      }
    }

    & > span {
      color: $colorOrange;
    }
  }
}

.important {
  & > span {
    color: $colorOrange;
  }
}
