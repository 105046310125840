.main {
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.input {
  border: 2px solid rgba(255, 255, 255, 0.5);
  display: flex;
  max-width: 522px;
  height: 56px;
  width: 100%;
  color: #ffffff;
  font-weight: inherit;
  font-size: inherit;
  line-height: 25px;
  background-color: rgba(0, 0, 0, 0);
  outline: none;
  margin-top: 25px;
  padding-left: 10px;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out;
  &:hover {
    border-color: #d2d2d2;
  }
  &:focus {
    border-color: #ffa012;
  }

  @media (max-width: 768px) {
    height: 29px;
    margin-top: 15px;
  }

  &::placeholder {
    color: inherit;
    font-size: inherit;
    opacity: 0.5;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: none;
  background: transparent;
  float: left;
  background-color: transparent;
  margin-top: 8px;
  width: 240px;
  height: 74px;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 0;
    height: 0;
    margin-bottom: 28px;
    margin-top: 21.5px;
  }
}
.buttonIcon {
  position: absolute;
  inset: auto;
  color: rgba(#ffffff, 0.3);
  border: 1px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: color 150ms ease-in-out;
  &:hover {
    color: rgba(#d2d2d2, 1);
  }
  &:active {
    color: rgba(#ffa012, 1);
  }

  @media (max-width: 768px) {
    width: 120px;
    height: 37px;
  }
}

.buttonText {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
  @media (max-width: 768px) {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
  }
}
.privacyPolicy {
  & > span {
    color: #ffa012;
  }
}
.infoPrivacyPolicy {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.5);
  gap: 10px;
  margin-top: 10px;

  @media (max-width: 768px) {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
  }
}
.mainMainButton {
  display: flex;
  flex-direction: row-reverse;
  gap: 5px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
    gap: 21px;
    align-items: center;
  }
}
