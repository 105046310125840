.main {
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    padding: 0 25px;
  }

  &:first-child {
    & .answers {
      max-height: 250px;
      @media (max-width: 768px) {
        max-height: none;
      }
    }
  }
}

.title {
  font-weight: 900;
  text-transform: uppercase;
  @media (max-width: 768px) {
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 12px;
    line-height: 25px;
  }
}

.description {
  opacity: 0.5;
  margin-bottom: 20px;
  min-height: 25px;
  @media (max-width: 768px) {
    font-size: 12px;
    display: flex;
    justify-content: center;
    text-align: center;
  }
}

.answers {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 410px;
  gap: 24px;
  white-space: nowrap;
  @media (max-width: 768px) {
    max-height: none;
    gap: 18px;
    white-space: initial;
  }
}

.customAnswer {
  display: flex;
  align-items: center;
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.customAnswerMarker {
  min-width: 15px;
  min-height: 15px;
  background: rgba(255, 255, 255, 0.25);
  border: 2px solid #ffffff;
  border-radius: 50%;
  margin-right: 14px;
  cursor: pointer;
  transition: background-color 150ms ease-in-out, border 150ms ease-in-out;

  &.checked {
    background: #ffa012;
    border: 2px solid #ffa012;
  }
}
.customAnswerInput {
  font-family: "Blender Pro";
  outline: none;
  background-color: transparent;
  color: inherit;
  border: none;
  font-size: inherit;
  font-weight: 250;
  font-size: 24px;
  line-height: 25px;
  color: #ffffff;
  border-bottom: 2px rgba(217, 217, 217, 0.5) solid;
  padding: 3px;
  transition: color 150ms ease-in-out, border-bottom 150ms ease-in-out;
  width: 100%;

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 14px;
  }

  &::placeholder {
    color: inherit;
    font-size: inherit;
  }

  &.checked {
    color: #ffa012;
    border-bottom: 2px #ffa012 solid;
  }
}
