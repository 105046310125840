.main {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.marker {
  min-width: 15px;
  min-height: 15px;
  background: rgba(255, 255, 255, 0.25);
  border: 2px solid #ffffff;
  border-radius: 50%;
  margin-right: 14px;
  transition: background-color 150ms ease-in-out, border 150ms ease-in-out;

  &.checked {
    background: #ffa012;
    border: 2px solid #ffa012;
  }
}

.title {
  transition: color 150ms ease-in-out;
  &.checked {
    color: #ffa012;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 14px;
  }
}
