.main {
  display: flex;
  align-items: baseline;
  
}

.title {
  display: flex;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.5);
  margin-right: 12px;
  @media (max-width: 768px) {
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    margin-right: 4px;
  }
}

.languages {
  display: flex;
  align-items: center;
}

.lang {
  display: flex;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.75);
  text-transform: uppercase;
  cursor: pointer;
  transition: color 200ms ease-in-out;
  @media (max-width: 768px) {
    font-weight: 700;
    font-size: 10px;
    line-height: 25px;

  }

  &:not(:last-child) {
    margin-right: 10px;
    @media (max-width: 768px) {
      margin-right: 3px;
    }
  }

  &.active {
    color: #ffa012;
  }

  &:hover {
    color: rgba(255, 255, 255, 0.55);
  }
}
