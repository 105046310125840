@font-face {
  font-family: "Blender Pro";
  font-weight: 500;
  src: url("../../public/fonts/BlenderPro-Medium.woff2") format("woff2"),
    url("../../public/fonts/BlenderPro-Medium.woff") format("woff");
}

@font-face {
  font-family: "Blender Pro";
  font-weight: 900;
  src: url("../../public/fonts/BlenderPro-Bold.woff2") format("woff2"),
  url("../../public/fonts/BlenderPro-Bold.woff") format("woff");
}

@font-face {
  font-family: "Blender Pro";
  font-weight: 250;
  src: url("../../public/fonts/BlenderPro-Thin.woff2") format("woff2"),
  url("../../public/fonts/BlenderPro-Thin.woff") format("woff");
}